import React from 'react'

const padStyle = {
    margin: "0 0 _size(element-margin) 0",
    paddingLeft: "3.25em",
    position: "relative"
};

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <div className="contact-method">
                    <span className="icon alt fas fa-envelope"></span>
                    <h3>Email</h3>
                    <a href="mailto:kplomp@up.edu.ph">kplomp@up.edu.ph</a><br />
                    <a href="mailto:kplomp@sfu.ca">kplomp@sfu.ca</a>
                </div>
                <div className="contact-method">
                    <span className="icon alt fas fa-thumbs-up"></span>
                    <h3>Social</h3>
                </div>
                <ul className="icons" style={padStyle}>
                    <li><a href="https://twitter.com/kimberly_plomp" target="_blank" rel="noreferrer" aria-label="Twitter" className="icon alt fab fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://www.linkedin.com/in/kimberly-plomp-74255a49/" target="_blank" rel="noreferrer" aria-label="LinkedIn" className="icon alt fab fa-linkedin-in"><span className="label">LinkedIn</span></a></li>
                    <li><a href="https://www.researchgate.net/profile/Kimberly-Plomp" target="_blank" rel="noreferrer" aria-label="ResearchGate" className="icon alt fab fa-researchgate"><span className="label">ResearchGate</span></a></li>
                    <li><a href="https://scholar.google.ca/citations?user=SXqf-akAAAAJ" target="_blank" rel="noreferrer" aria-label="Google Scholar" className="icon alt fab fa-google"><span className="label">Google Scholar</span></a></li>
                </ul>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fas fa-university"></span>
                        <h3><a href="http://asp.upd.edu.ph/" target="_blank" rel="noreferrer">University of the Philippines</a></h3>
                        <span>Archaeological Studies Program<br />
                        Albert Hall Building<br />
                        Lakandula Street corner E.Jacinto Street<br />
                        Diliman, Quezon City 1101<br />
                        Philippines</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
