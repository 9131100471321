import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="copyright">
                <li>&copy; <Link to="/">Kimberly Plomp, PhD</Link></li><li>Design: <a href="https://html5up.net" target="_blank" rel="noreferrer">HTML5 UP</a></li><li>Images: <a href="https://chaosincolor.com" target="_blank" rel="noreferrer">Annie Dunn</a> and <a href="https://unsplash.com/" target="_blank" rel="noreferrer">Unsplash</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
